<template>
  <div class="d-flex align-items-center justify-content-center">
    <div
      class="account-pages my-2 my-lg-5 pt-sm-5 p-2 p-lg-5 mx-auto content_style"
    >
      <div class="container p-4">
        <div class="row justify-content-center">

          <div class="">

            <div class="dropdown d-none d-lg-inline-block ms-1">

              <button type="button" class="btn header-item noti-icon waves-effect" data-bs-toggle="modal" data-bs-target=".bs-example-modal-lg">
                <span class="text-danger fw-bold">Comment ça marche ?</span>
              </button>

            </div>

          </div>
          
          <div class="modal fade bs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
              <div class="modal-dialog modal-lg">
                  <div class="modal-content">
                      <div class="modal-header">
                          <h5 class="modal-title fw-bold" id="myLargeModalLabel">Étapes d'obtention de votre fiche validée</h5>
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div class="modal-body">
                          <p>
                          <span class="fw-bold">1. Imprimer la Fiche de Préinscription :</span> <br> Tout d'abord, l'étudiant doit imprimer sa fiche de préinscription depuis son espace étudiant.</p>
                          <p><span class="fw-bold">2. Payer les Frais le Cas Échéant :</span> <br> Si des frais sont applicables selon son statut, l'étudiant doit se rendre à la banque pour effectuer le paiement.</p>
                          <p><span class="fw-bold">3. Prendre un Rendez-vous :</span> <br> Après le paiement (ou s'il n'y a pas de frais à payer), l'étudiant doit prendre un rendez-vous dans son espace étudiant.</p>
                          <p><span class="fw-bold">4. Imprimer le Bordereau de Rendez-vous :</span> <br> S'il réussit à prendre un rendez-vous, un bordereau lui est généré. Il doit conserver ce bordereau.</p>
                          <p><span class="fw-bold">5. Imprimer la Carte Étudiante :</span> <br> Le jour du rendez-vous, l'étudiant doit se rendre sur le site spécifié pour imprimer sa carte étudiante.</p>
                          <p><span class="fw-bold">6. Imprimer la Fiche d'Étudiant Validée :</span> <br> Après avoir obtenu sa carte étudiante, l'étudiant doit retourner dans son espace étudiant. <br> 
                            Là, il peut imprimer sa fiche d'étudiant validée, confirmant ainsi sa préinscription
                          </p>
                          <p>
                            Ce processus assure une validation complète de la préinscription, incluant le paiement des frais, la prise de rendez-vous, l'impression de la carte étudiante, et enfin, l'impression de la fiche d'étudiant validée. 
                            Il est essentiel que l'étudiant suive chacune de ces étapes pour finaliser son inscription de manière correcte et complète.</p>                          
                      </div>
                  </div><!-- /.modal-content -->
              </div><!-- /.modal-dialog -->
            </div>

          <div class="col-md-12 col-lg-6 offset-xl-1 col-xl-5">
            <div class="overflow-hidden">
              
              <div class="card-body pt-4">
                <div class="auth-logo text-center">
                  <img
                    src="assets/images/logo-uac.png"
                    alt=""
                    class="rounded-circle"
                    height="100"
                  />
                  <h5 class="mt-3 text-black font-size-24 fw-semibold">
                    Connexion
                  </h5>
                  <p class="text-danger">{{ errorMessage }}</p>
                </div>
                <div class="mt-1 text-center">
                
              </div>

                <div class="p-2">
                  <form class="form-horizontal" @submit.prevent="login">
                    <div class="mb-3">
                      <label for="username" class="form-label"
                        >Nom d'utilisateur</label
                      >
                      <input
                        required
                        type="text"
                        class="form-control" style="padding:12px;"
                        id="username"
                        v-model="user.email"
                        placeholder="Entrez votre nom d'utilisateur"
                      />
                    </div>

                    <div class="mb-3">
                      <label class="form-label">Mot de passe</label>
                      <div class="input-group auth-pass-inputgroup">
                        <input
                          required
                          type="password"
                          class="form-control" style="padding:12px;"
                          v-model="user.password"
                          placeholder="Entrez votre mot de passe"
                          aria-label="Password"
                          aria-describedby="password-addon"
                          ref="inputPassword"
                        />
                        <button
                          class="btn btn-light"
                          type="button"
                          id="password-addon"
                          @click="showPassword"
                        >
                        
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-eye" viewBox="0 0 16 16" v-if="this.isPassword">
                          <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                          <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                        </svg> 
                        
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16" v-if="!this.isPassword">
                          <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"/>
                          <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299l.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"/>
                          <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884l-12-12 .708-.708 12 12-.708.708z"/>
                        </svg>
                        </button>
                      </div>
                    </div>

                    <!-- <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="remember-check"
                      />
                      <label class="form-check-label" for="remember-check">
                        Se rappeler de moi.
                      </label>
                    </div> -->

                    <div class="mt-3 d-grid">
                      <button
                        class="btn waves-effect waves-light btn-login text-white fs-5 fw-bold" style="background-color:rgba(9, 173, 149, 1);"
                        type="submit"
                      >
                        Se connecter
                      </button>
                    </div>

                    <div class="mt-4 d-flex justify-content-center">
                      <router-link to="/forgot_password"
                        > Mot de passe oublié
                        ?</router-link
                      >
                    </div>
                  </form>
                </div>
              </div>
             
            </div>
          </div>
          <div
            class="col-lg-6"
            style="
              background-image: url('assets/images/studentImg.png');
              background-repeat: no-repeat;
              background-size: contain;
            "
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
// import ref from 'vue'

export default {
  name: "Login",

  data() {
    return {
      user: {
        email: "",
        password: "",
        
      },
      errorMessage: "",
      isPassword: false
    };
  },

 
  // validations: {
  //         input: {
  //         username: { required },
  //         password: { required }
  //         }
  // },
  mounted() {
    document.body.classList.add("login-body-class");
  },
  beforeUnmount() {
    document.body.classList.remove("login-body-class");
  },
  methods: {
    
    login() {
      this.$url
        .post("login", this.user)
        .then((response) => {
          
          this.$store.dispatch("user", response.data.token);
          
          localStorage.setItem("num_table", response.data.user.num_table);
          localStorage.setItem("annee_bac", response.data.user.annee_bac);
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("email", response.data.user.email);
          localStorage.setItem("user_id", response.data.user.id);
          localStorage.setItem(
            "login_attempt",
            response.data.user.login_attempt
          );
         

          this.$router.push('/dashboard');
        })
        .catch((error) => {
          console.log(error);
          this.$toast.error(error.response.data.message, {
            position: "top-right",
            duration: 5000,
          });
        });
    },
    showPassword(){
      const inputPassword = this.$refs.inputPassword;
      if(inputPassword.type==="password"){
        inputPassword.type="text"
        this.isPassword=true
      }else{
        inputPassword.type="password"
        this.isPassword=false
      }
    }
  },
};
</script>

<style>
@media (min-width: 992px) { 
  .content_style {
    box-shadow: 0px 0px 25px 5px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: 1200px !important;
  }

  @media (max-width: 576px) {
  .content_style {
    box-shadow: none; 
    width: auto !important; 
  }
}
}
</style>

