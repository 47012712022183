<template>
    <header id="page-topbar">
      <div class="navbar-header">
        <div class="d-flex">
          <!-- LOGO -->
          <div class="navbar-brand-box">
            <a href="index.html" class="logo logo-dark">
              <span class="logo-sm">
                <img src="assets/images/logo_uac.png" alt="" height="22" />
              </span>
              <span class="logo-lg">
                <img src="assets/images/logo_uac.png" alt="" height="17" />
              </span>
            </a>

            <a href="index.html" class="logo logo-light">
              <span class="logo-sm">
                <img src="/assets/images/logo_uac.png" alt="" height="22" />
              </span>
              <span class="logo-lg">
                <img
                  src="/assets/images/logo_uac.png"
                  alt=""
          
                  height="42"
                />
              </span>
            </a>
          </div>
          <button class="btn btn-offcanvas" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasTop" aria-controls="offcanvasTop">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
            </svg>
          </button>
         
        </div>

        <div class="d-flex">

          
          

          <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasTop" aria-labelledby="offcanvasTopLabel">
            <div class="offcanvas-header">
              <h3 id="offcanvasTopLabel">Espace étudiant</h3>
              <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body">
              <ul class=" list-unstyled list-menu">
                <li class="p-3 border-bottom border-2" aria-label="Close" data-bs-dismiss="offcanvas" ><router-link to="/fiche_inscription" class="w-100 mobile-link" >Inscription</router-link></li>
                <li class="p-3 border-bottom border-2" aria-label="Close" data-bs-dismiss="offcanvas"><router-link to="" class="w-100 mobile-link" >Fichiers</router-link></li>
                <li class="p-3 border-bottom border-2" aria-label="Close" data-bs-dismiss="offcanvas" ><router-link to="" class="w-100 mobile-link"  >Actes académiques</router-link></li>
                <li class="p-3 border-bottom border-2" aria-label="Close" data-bs-dismiss="offcanvas"><router-link to="" class="w-100 mobile-link" >Mail institutionel</router-link></li>
              </ul>
            </div>
          </div>

          <div class="dropdown d-none d-lg-inline-block ms-1">

            <button type="button" class="btn header-item noti-icon waves-effect" data-bs-toggle="modal" data-bs-target=".bs-example-modal-lg">
              <span class="text-danger fw-bold">Comment ça marche ?</span>
            </button>
           

            
          </div>


          <div class="dropdown d-inline-block">
            <button
              type="button"
              class="btn header-item waves-effect"
              id="page-header-user-dropdown"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img
                class="rounded-circle header-profile-user me-1"
                src="assets/images/users/user.png"
                alt="Header Avatar"
              />
              <span class="d-none d-xl-inline-block mx-1" key="t-vital"
                >{{ email }}</span
              >
              <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-end">
              <!-- item-->
              <a class="dropdown-item" href="#"
                ><i class="bx bx-user font-size-16 align-middle me-1"></i>
                <span key="t-profile">Profil</span></a
              >

              <!-- <a class="dropdown-item" href="#"
                ><i class="me-1 font-size-16">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383l-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z"/>
                  </svg>
                </i>
                <span key="t-profile">{{ mail_institutionnel }}</span></a
              > -->
              

              <a class="dropdown-item" data-bs-toggle="modal" data-bs-target=".bs-example-modal-lg">
                <i class="bx bx-question-mark font-size-16 align-middle me-1"></i>
                <span class="fw-bold">Comment ça marche ?</span>
              </a>

              <div class="dropdown-divider"></div>
              <a class="dropdown-item text-danger" href="#" @click.prevent="logout"
                ><i
                  class="bx bx-power-off font-size-16 align-middle me-1 text-danger"
                ></i>
                <span key="t-logout">Déconnexion</span></a
              >
            </div>
          </div>
        </div>
      </div>

    </header>

    <div class="modal fade bs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
              <div class="modal-dialog modal-lg">
                  <div class="modal-content">
                      <div class="modal-header">
                          <h5 class="modal-title fw-bold" id="myLargeModalLabel">Étapes d'obtention de votre fiche validée</h5>
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div class="modal-body">
                          <p>
                          <span class="fw-bold">1. Imprimer la Fiche de Préinscription :</span> <br> Tout d'abord, l'étudiant doit imprimer sa fiche de préinscription depuis son espace étudiant.</p>
                          <p><span class="fw-bold">2. Payer les Frais le Cas Échéant :</span> <br> Si des frais sont applicables selon son statut, l'étudiant doit se rendre à la banque pour effectuer le paiement.</p>
                          <p><span class="fw-bold">3. Prendre un Rendez-vous :</span> <br> Après le paiement (ou s'il n'y a pas de frais à payer), l'étudiant doit prendre un rendez-vous dans son espace étudiant.</p>
                          <p><span class="fw-bold">4. Imprimer le Bordereau de Rendez-vous :</span> <br> S'il réussit à prendre un rendez-vous, un bordereau lui est généré. Il doit conserver ce bordereau.</p>
                          <p><span class="fw-bold">5. Imprimer la Carte Étudiante :</span> <br> Le jour du rendez-vous, l'étudiant doit se rendre sur le site spécifié pour imprimer sa carte étudiante.</p>
                          <p><span class="fw-bold">6. Imprimer la Fiche d'Étudiant Validée :</span> <br> Après avoir obtenu sa carte étudiante, l'étudiant doit retourner dans son espace étudiant. <br> 
                            Là, il peut imprimer sa fiche d'étudiant validée, confirmant ainsi sa préinscription
                          </p>
                          <p>
                            Ce processus assure une validation complète de la préinscription, incluant le paiement des frais, la prise de rendez-vous, l'impression de la carte étudiante, et enfin, l'impression de la fiche d'étudiant validée. 
                            Il est essentiel que l'étudiant suive chacune de ces étapes pour finaliser son inscription de manière correcte et complète.</p>                          
                      </div>
                  </div><!-- /.modal-content -->
              </div><!-- /.modal-dialog -->
            </div>
</template>

<script>
import router from '@/router'
export default {
    name: 'Header',
    data(){
        return {
          userName:"",
          email: '',
          id:'',
          token: '',
          mail_institutionnel: ''
        }
    },
    mounted(){
      this.email = localStorage.getItem('email')
      this.userName = localStorage.getItem('user_name')
      this.token = localStorage.getItem('token')
      

      // this.$url3.get('user/mail/'+this.email).then(response=>{
      //   console.log(response)
      // }).then(error =>{
      //   console.log(error)
      // })

      
    },
    computed:{
      ficheBachelier(){
        return this.$store.state.ficheBacheliers?this.$store.state.ficheBacheliers.studentFiches.length:0
      }
    },
    methods:{
      logout(){
        this.$url.post('logout')
        .then(response => {
          localStorage.removeItem('num_table')
          localStorage.removeItem('annee_bac')
          localStorage.removeItem('token')
          localStorage.removeItem('email')
          localStorage.removeItem('user_id')
          localStorage.removeItem('login_attempt')
          router.push('/')
          console.log(response)
        })
      }
    }
}
</script>

<style scoped>
  .list-menu>li:hover{
    background-color: #34c38f;
    color: white;
  }
  .mobile-link{
    color: black;
  }
  .list-menu>li:hover .mobile-link{
    color: white;
  }
  
  .offcanvas-body{
    padding:0;
  }
  .btn-offcanvas{
    display: none;
  }

  @media screen and (max-width: 990px) {
    .btn-offcanvas{
      display: block;
    }
  }
</style>