<template>
    <div class="vertical-menu">

<div data-simplebar class="h-100">

    <!--- Sidemenu -->
    <div id="sidebar-menu">
        <!-- Left Menu Start -->
        <ul class="metismenu list-unstyled" id="side-menu">
            <li class="menu-title text-white fs-4 font-bold" key="t-menu">Espace étudiant</li>

            <div v-if="!this.isPrintedCard" >
                <li>
                    <router-link to="/fiche_inscription" href="javascript: void(0);" class=" waves-effect text-white"   >
                        <i class="bx bx-file"></i>
                        <span key="t-ecommerce">Inscription</span>
                    </router-link>
                </li>
            </div>

            <li>
                <a href="javascript: void(0);" class="waves-effect text-white">
                    <i class="bx bx-file"></i>
                    <span key="t-crypto">Mail institutionnel</span>
                </a>
                
            </li>
        </ul>
    </div>
    <!-- Sidebar -->
</div>
</div>
</template>

<script>



export default {
    name: 'Sidebar',

    data() {
        return {
        data: [],
        isPrintedCard: false,
        };
    },

    mounted() {

        const config = {
            headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Access-Control-Allow-Credentials": "",
            "Access-Control-Allow-Headers": "",
            "Access-Control-Allow-Methods": "*",
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            },
        };

        this.$url.get("stockage", config)
            .then((resp) => {
                let mat = localStorage.getItem('email').slice(-8)

                let pp = resp.data
                console.log(mat)

                for (let index = 0; index < pp.length; index++) {
                    if (pp[index].matricule == mat) {
                   
                    this.isPrintedCard = pp[index].is_printed_cart
                    break;
                    }
                }

              
            })
            .catch((error) => {
                console.error(error);
            });
        
        // this.$url1.get('get_fiche_etudiant/'+localStorage.getItem('email').slice(-8), config).then(response=>{
        //     console.log(response)
        //     this.$store.dispatch('autoriser_impression_carte', response.data.fiche_etudiant.autoriser_impression);
        // }).catch(error => {
        //     console.log(error)
        // });
        
    },
}

</script>



<style lang="scss" scoped>

</style>