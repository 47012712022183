<template>
  <div id="layout-wrapper">
   
    <Header></Header>
    <Sidebar></Sidebar>

    <div class="main-content">
      <div class="page-content">
        <div class="container-fluid">
          <!-- <div class="row">
                <div class="col-12">
                  <div
                      class="page-title-box d-sm-flex align-items-center justify-content-between"
                  >
                      <h4 class="mb-sm-0 font-size-18">Espace étudiant</h4>

                      <div class="page-title-right">
                      <ol class="breadcrumb m-0">
                          <li class="breadcrumb-item active">Tableau de bord</li>
                      </ol>
                      </div>
                  </div>
                </div>
            </div> -->
          <router-view></router-view>

        </div>
      </div>
    </div>

    
  </div>
  <Footer></Footer>
</template>

<script>
import Sidebar from '@/components/Layouts/Sidebar.vue';
import Header from '@/components/Layouts/Header.vue';
import Footer from '@/components/Layouts/Footer.vue';
export default {
  components: { Sidebar, Header, Footer },
  name: "Template",
};
</script>

<style lang="scss" scoped></style>
