<template>
  
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">

          <div v-if="this.isPrintedCard" >
              <div class="d-flex flex-wrap">
                <h5 class="card-title mb-4">Document disponible</h5>

              <!-- <div class="ms-auto">
                <a href="javascript: void(0);" class="fw-medium text-reset"
                  >Tout voir</a
                >
              </div> -->
                <div class="table-responsive">
                <table class="table align-middle table-nowrap table-hover mb-0">
                  <thead class="table-light">
                    <tr>
                      <th class="align-middle">NOM</th>
                      <th class="align-middle">TYPE</th>
                      <th class="align-middle">ÉTAT</th>
                      <th class="align-middle">ÉMISE/VALIDÉE LE</th>
    
                      <th class="align-middle">ACTIONS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text-body fw-bold">
                        <img src="/assets/images/file.jpeg" width="28" height="30">
                        {{ this.info_entite.libelle }} |
                        {{ this.info_entite.designation }}
                      </td>
                      <td>
                        Attestation d'inscription
                      </td>
                      <td>
                        <div v-if="this.isPrintedCard" >
                          <span
                            class="badge badge-pill mdi font-size-15 badge-soft-success"
                          
                            ><i class="mdi mdi-clock-time-twelve-outline"></i>
                          Valide
                          </span>
                        </div>
                      </td>
                      <td>20-10-2016</td>
    
                      <td>
                        <!-- Button trigger modal -->
                        <div v-if="this.isPrintedCard" >
                          <a class="btn btn-outline-success btn-rounded btn-sm edit"
                            title="Télécharger"
                            @click.prevent="downloadFiche()"

                            
                          >
                            <i class="fas fa-download"></i>
                          </a>
                        </div>

                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    :class="{ 'hide d-none': !firstConnexion, 'show d-block': firstConnexion }"
    id="modal-backdrop"
    tabindex="-1"
    aria-labelledby="subscribeModalLabel"
    aria-hidden="true"
    style="padding-left: 0px"
    aria-modal="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-fullscreen modal-xl">
      <div class="modal-content">
        <div class="modal-header border-bottom-0">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="text-center mb-4">
            <div class="row justify-content-center align-items-center" style="height:85vh;">
              <div class="col-6">
                <h4 class="text-dark pb-3 fs-3">
                  Il est recommandé que vous changiez votre mot de passe dès la première connexion
                </h4>
                
                <div class="input-group bg-light rounded">
                  <input
                    type="text"
                    class="form-control bg-transparent border border-success"
                    v-model="login"
                    placeholder="Login"
                    required
                    readonly
                  />
                  <button
                    class="btn" style="background-color:rgba(9, 173, 149, 1); color:white;"
                    type="button"
                    id="button-addon2"
                  >
                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
                    <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
                  </svg>
                  
                  </button>
                </div>

                <div class="input-group bg-light rounded mt-4">
                  <input
                    type="password"
                    class="form-control bg-transparent border border-success"
                    v-model="old_password"
                    placeholder="Ancien mot de passe"
                    required
                    ref="oldPassword"
                  />
                  <button
                    class="btn" style="background-color:rgba(9, 173, 149, 1); color:white;"
                    type="button"
                    id="button-addon2"
                    @click="showOldPassword"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="bi bi-eye"
                      viewBox="0 0 16 16"
                      v-if="this.isOldPassword"
                    >
                      <path
                        d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
                      />
                      <path
                        d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
                      />
                    </svg>

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="bi bi-eye-slash"
                      viewBox="0 0 16 16"
                      v-if="!this.isOldPassword"
                    >
                      <path
                        d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"
                      />
                      <path
                        d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299l.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"
                      />
                      <path
                        d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884l-12-12 .708-.708 12 12-.708.708z"
                      />
                    </svg>
                  </button>
                </div>

                <div
                  class="input-group bg-light rounded mt-4"
                  :class="{
                    'border border-danger': this.showValidationMessage,
                    'border border-success': !this.showValidationMessage,
                  }"
                >
                  <input
                    type="password"
                    class="form-control bg-transparent border-0"
                    v-model="new_password"
                    placeholder="Nouveau mot de passe"
                    required
                    ref="newPassword"
                    @input="validateInput"
                  />
                  <button
                    @click="showNewPassword"
                    class="btn" style="background-color:rgba(9, 173, 149, 1); color:white;"
                    type="button"
                    id="button-addon2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="bi bi-eye"
                      viewBox="0 0 16 16"
                      v-if="this.isNewPassword"
                    >
                      <path
                        d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
                      />
                      <path
                        d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
                      />
                    </svg>

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="bi bi-eye-slash"
                      viewBox="0 0 16 16"
                      v-if="!this.isNewPassword"
                    >
                      <path
                        d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"
                      />
                      <path
                        d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299l.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"
                      />
                      <path
                        d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884l-12-12 .708-.708 12 12-.708.708z"
                      />
                    </svg>
                  </button>
                </div>

                <p class="text-danger" v-if="this.showValidationMessage">
                  Le mot de passe doit contenir au moins 8 caractères, un
                  caractère spécial, une lettre majuscule, un chiffre et une
                  lettre minuscule
                </p>

                <div
                  class="input-group bg-light rounded mt-4"
                  :class="{
                    'border border-danger':
                      this.is_valide_password_confirmation,
                    'border border-success':
                      !this.is_valide_password_confirmation,
                  }"
                >
                  <input
                    type="password"
                    class="form-control bg-transparent border-0"
                    v-model="new_password_confirmation"
                    placeholder="Confirmez mot de passe"
                    aria-label="Recipient's username"
                    aria-describedby="button-addon2"
                    required
                    ref="confirmPassword"
                    @input="input_password_confirmation"
                  />

                  <button
                    @click="showConfirmPassword"
                    class="btn" style="background-color:rgba(9, 173, 149, 1); color:white;"
                    type="button"
                    id="button-addon2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="bi bi-eye"
                      viewBox="0 0 16 16"
                      v-if="this.isConfirmPassword"
                    >
                      <path
                        d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
                      />
                      <path
                        d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
                      />
                    </svg>

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="bi bi-eye-slash"
                      viewBox="0 0 16 16"
                      v-if="!this.isConfirmPassword"
                    >
                      <path
                        d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"
                      />
                      <path
                        d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299l.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"
                      />
                      <path
                        d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884l-12-12 .708-.708 12 12-.708.708z"
                      />
                    </svg>
                  </button>
                </div>

                <p
                  class="text-danger"
                  v-if="this.is_valide_password_confirmation"
                >
                  Les deux mots de passe doivent être identiques
                </p>

                <div class="d-flex justify-content-center mt-4">
                  <input
                    type="submit"
                    class="btn fs-5 fw-bold" style="background-color:rgba(9, 173, 149, 1); color:white;"
                    value="Enregistrer"
                    @click="handleUpdatePassword"
                  />
                </div>
                <p class="text-success text-center mt-4 fs-4">
                  {{ this.returnMessage }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Dashboard",

  data() {
    return {
      data: [],
      new_password: "",
      new_password_confirmation: "",
      showValidationMessage: false,
      is_valide_password_confirmation: false,
      password_is_update: false,
      old_password: "",
      login: localStorage.getItem("email"),
      returnMessage: "",
      firstConnexion: false,

      isOldPassword: false,
      isNewPassword: false,
      isConfirmPassword: false,
      nofichetext: false,

      isPrintedCard: false,
      data_fiche : {
        matricule: localStorage.getItem('email').slice(-8),
        id: null,
        annee_bac: null,
        num_table:null,
        annee_academique: "2023-2024"
      },
      site_id : null,
      info_entite: {
        libelle: null,
        designation: null
      }
    };
  },

  mounted() {

    const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Credentials": "",
          "Access-Control-Allow-Headers": "",
          "Access-Control-Allow-Methods": "*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      };

    this.$url.post(
        "choixBachelierLast", {
          num_table: localStorage.getItem("num_table"),
          annee_bac: localStorage.getItem("annee_bac"),
        },
        config
      )
      .then((response) => {
        console.log(response)
        this.data = response.data

        console.log(localStorage.getItem("num_table"))
        
        this.data_fiche.id = response.data[0].specialite.entite_gestionnaire_id
        this.info_entite.designation = response.data[0].specialite.entite_gestionnaire.libelle
        this.info_entite.libelle = response.data[0].specialite.designation
      })
      .catch((error) => {
        console.log(error.response.data.statut);
      });

    this.$url.get("stockage")
          .then((resp) => {
            let mat = localStorage.getItem('email').slice(-8)

              let pp = resp.data
            console.log(mat)

              for (let index = 0; index < pp.length; index++) {
                if (pp[index].matricule == mat) {
                  console.log("kfzbve")
                  this.isPrintedCard = pp[index].is_printed_cart
                  break;
                }
              }

              console.log("le bye est ", this.isPrintedCard)
          })
          .catch((error) => {
            console.error(error);
          });

    if (localStorage.getItem("login_attempt") == 0) {
      this.firstConnexion = true;
    } else {
      this.firstConnexion = false;
    }
  },
  computed: {
    valideInscriptionExist() {
      const hasValidRecord = this.data.some((record) => record.validite === true);
      return hasValidRecord ? true : false;
    },

    valideFicheExist() {
      const hasValidRecord = this.data.some((record) => record.is_printed_cart === true);
      return hasValidRecord ? true : false;
    },

    ficheBachelier() {
      return this.$store.state.ficheBacheliers
        ? this.$store.state.ficheBacheliers.studentFiches.length
        : 0;
    },
  },

  methods: {
    formatDate(k) {
      const d = new Date(k);
      return (
        ("0" + d.getDate()).slice(-2) +
        "-" +
        ("0" + (d.getMonth() + 1)).slice(-2) +
        "-" +
        d.getFullYear() +
        " " +
        ("0" + d.getHours()).slice(-2) +
        ":" +
        ("0" + d.getMinutes()).slice(-2)
      );
    },
    goToTakeEmail() {
      //router.push('/askEmail')
      window.location.href = "/askEmail";
    },
    handleUpdatePassword() {
      console.log(localStorage.getItem('token'))
      const config = {
        headers: {
          "Access-Control-Allow-Credentials": "",
          "Access-Control-Allow-Headers": "",
          "Access-Control-Allow-Methods": "*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      };
      let password_format = /^(?=.*[A-Z])(?=.*\d)(?=.*\W)[a-zA-Z\d\W]{8,}$/;
      if (
        this.new_password == "" ||
        this.new_password_confirmation == "" ||
        this.new_password == null ||
        this.new_password_confirmation == null
      ) {
        this.$toast.error("Veillez remplireeeeeeeee tous les champs", {
          position: "top-right",
          duration: 3000,
        });
      } else if (!password_format.test(this.new_password)) {
        this.$toast.error("Le format de mot de passe ne correspond pas", {
          position: "top-right",
          duration: 3000,
        });
      } else {
        this.$url
          .post(
            "update_password",
            {
              old_password: this.old_password,
              email: localStorage.getItem("email"),
              password: this.new_password,
              password_confirmation: this.new_password_confirmation,
            },
            config
          )
          .then((response) => {
            console.log(response)

            if(response.data.status==true){
              this.firstConnexion = false;
              localStorage.setItem("login_attempt", 1);
              this.$toast.success("Mot de passe mis à jour avec succès", {
                position: "top-right",
                duration: 3000,
              });
              this.$router.push('fiche_inscription')
            }else{
              this.$toast.error(response.data.message, {
              position: "top-right",
              duration: 3000,
            });
            }
            

          })
          .catch((error) => {
            console.log(error.response.data.message);
            this.$toast.error(error.response.data.message, {
              position: "top-right",
              duration: 3000,
            });
          });
      }
    },

    validateInput() {
      const regex = /^(?=.*[A-Z])(?=.*\d)(?=.*\W)[a-zA-Z\d\W]{8,}$/;
      if (!regex.test(this.new_password)) {
        this.showValidationMessage = true;
      } else {
        this.showValidationMessage = false;
      }
    },
    input_password_confirmation() {
      if (this.new_password != this.new_password_confirmation) {
        this.is_valide_password_confirmation = true;
      } else {
        this.is_valide_password_confirmation = false;
      }
    },
    showOldPassword() {
      const inputPassword = this.$refs.oldPassword;
      if (inputPassword.type === "password") {
        inputPassword.type = "text";
        this.isOldPassword = true;
      } else {
        inputPassword.type = "password";
        this.isOldPassword = false;
      }
    },
    showNewPassword() {
      const inputPassword = this.$refs.newPassword;
      if (inputPassword.type === "password") {
        inputPassword.type = "text";
        this.isNewPassword = true;
      } else {
        inputPassword.type = "password";
        this.isNewPassword = false;
      }
    },
    showConfirmPassword() {
      const inputPassword = this.$refs.confirmPassword;
      if (inputPassword.type === "password") {
        inputPassword.type = "text";
        this.isConfirmPassword = true;
      } else {
        inputPassword.type = "password";
        this.isConfirmPassword = false;
      }
    },


    downloadFiche(){
      this.data_fiche.annee_bac = localStorage.getItem('annee_bac')
      this.data_fiche.num_table = localStorage.getItem('num_table')
      console.log(this.data_fiche)
      this.$url.post('get_fiche_valide',this.data_fiche, {
        responseType: 'blob',
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));

        // Créez un élément d'ancrage pour déclencher le téléchargement
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'attestation_inscription.pdf'); // Nom du fichier PDF téléchargé
        document.body.appendChild(link);
        link.click();

        // Libérez la mémoire du lien
        window.URL.revokeObjectURL(url);
        this.$toast.success("Veuillez técharger votre fiche ", {
          position: "top-right",
          duration: 5000,
        });
      })
      .catch((error) => {
        console.error("Erreur lors du téléchargement du fichier PDF :", error);
        // Affichez une notification d'erreur (facultatif)
        this.$toast.error("Erreur lors du téléchargement de la fiche de preinscription");
      });
    },
   
    // downloadItem (fiche) {
    //   this.$url.get('telecharger-fichier/'+fiche.name, { responseType: 'blob' })
    //     .then(response => {
    //       const blob = new Blob([response.data], { type: 'application/pdf' })
    //       const link = document.createElement('a')
    //       link.href = URL.createObjectURL(blob)
    //       link.download = fiche.name
    //       link.click()
    //       URL.revokeObjectURL(link.href)
    //     }).catch(console.error)
    // }

  },
};
</script>

<style lang="css" scoped>
/* .apointment{
        cursor: pointer;
    } */
.actionToTakeEmail:hover {
  color: white;
}
.actionToTakeEmail {
  cursor: pointer;
}

.stepper-element{
    z-index: 1;
  }
  .stepper-element>div{
    background-color: rgba(217, 217, 217, 1);
    height: 60px; 
    width: 60px;
  }
  .stepper-active{
    background-color: rgba(242, 7, 47, 1) !important;
  }
  .stepper-progress{
    background-color: rgba(217, 217, 217, 1);
    height:3px;
    top: 30%;
  }
  .top_bar{
    background-color: silver;
    height: 2px;
    width:90%;
  }

    @media (max-width: 576px) {
 
  .stepper-element>div{
    background-color: rgba(217, 217, 217, 1);
    height: 40px; 
    width: 40px;
    }
    
    .position-relative.stepper-content > div > p{
      font-size: 14px;
      margin-bottom: 0rem !important;
      margin-left:1rem;
    }
  }
</style>
