<template>
  <div>
    <router-view :key="$route.name"></router-view>
  </div>
</template>

<script>
import router from '@/router'

export default {
    name:'App',

    data() {
      return {
        maxInactiveTime: 30 * 60 * 1000, // 30 minutes en millisecondes
        inactivityTimer: null,
        isConnected: navigator.onLine,
      };
    },


    created() {
      this.startInactivityTimer();
      window.addEventListener("mousemove", this.resetInactivityTimer);
      window.addEventListener("keydown", this.resetInactivityTimer);
    },

    mounted() {
      
      window.addEventListener('online', this.updateOnlineStatus)
      window.addEventListener('offline', this.updateOnlineStatus)
  
    },

    beforeUnmount() {
      window.removeEventListener('online', this.updateOnlineStatus)
      window.removeEventListener('offline', this.updateOnlineStatus)
    },

   
    
    
    methods: {

      updateOnlineStatus() {

        this.isConnected = navigator.onLine;
        const message = this.isConnected ? 'Connexion rétablie' : 'Connexion perdue';

        if(this.isConnected){
          
          this.$toast.success(message, {
            position: "top-right",
            duration: 5000,
          });
        }else{
          this.$toast.error(message, {
            position: "top-right",
            duration: 5000,
          });
        }
      },
   

      resetInactivityTimer() {
        clearTimeout(this.inactivityTimer);
        this.startInactivityTimer();
      },
      startInactivityTimer() {
        this.inactivityTimer = setTimeout(this.logoutUser, this.maxInactiveTime);
      },
      logoutUser() {
        localStorage.removeItem('num_table')
        localStorage.removeItem('annee_bac')
        localStorage.removeItem('token')
        localStorage.removeItem('email')
        localStorage.removeItem('user_id')
        localStorage.removeItem('login_attempt')
        router.push('/')
      }

      
    },

    computed:{

      showDashboard() {
      const routeMeta = this.$route.meta;
      return routeMeta.requiresAuth; // Afficher le dashboard si l'accès nécessite une authentification
      },
      showLoginOrRegister() {
        const routeMeta = this.$route.meta;
        return !routeMeta.requiresAuth; // Afficher les pages de connexion/inscription si l'accès ne nécessite pas d'authentification
      }
    },
    

}
</script>
