<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-md-12">

        <div class="modal-body">
          <div class="text-center mb-4 card">
            <form @submit.prevent>
                <div class="form-group modal-body col-md-6" v-if="addingMode">
                    <label for="start_date">Date du rendez-vous</label>
                    <input
                      type="date"
                      id="start_date"
                      class="form-control"
                      v-model="newEvent.start_date"
                      :min="today"
                      :disabled="entrl"
                    />

                    <!-- <datepicker v-model="newEvent.start_date" :disabled-dates="datePickerDisabledDates"> eiorobeog </datepicker> -->

                    <div class="" v-if="addingMode">
                    <button class="btn btn-sm btn-primary mt-2" @click="addNewEvent">
                      Enregistrer votre rendez-vous
                    </button>
                  </div>
                <!-- <div class="col-md-6 mb-4" v-if="addingMode">
                  <button class="btn btn-sm btn-primary mt-2" @click="addNewEvent">
                    Enrégistrer
                  </button>
                </div> -->
                
                <!-- <template v-else>
                  <div class="col-md-6 mb-4">
                    <button class="btn btn-sm btn-success" @click="updateEvent">
                      Update
                    </button>
                    <button class="btn btn-sm btn-danger" @click="deleteEvent">
                      Delete
                    </button>
                    <button
                      class="btn btn-sm btn-secondary"
                      @click="cancelEvent"
                    >
                      Cancel
                    </button>
                  </div>
                </template> -->
              </div>
            </form>
         </div>
        </div>
                     

        <div class="modal-body">
          <div class="text-center mb-4">
            <div class="row justify-content-center align-items-center">
        
                <div class="">
                  <div v-if="addingMode">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="card">
                          <div class="card-body">
                            <h4 class="card-title mb-4">Site du rendez vous</h4>
                            <div class="table-responsive">
                              <h5 class="">Le site de votre rendez-vous apparaitra ici.</h5>
                            </div>
                            <!-- end table-responsive -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="!addingMode">
                    <div class="row">
                      <div class="col-lg-12" style="top: 250px;height: 80vh;">
                        <div class="card">
                          <div class="card-body">
                            <h2 class="card-title mb-4">Site du rendez vous</h2>
                            <div class="table-responsive">
                              <h2 class="">Chère étudiant, dans le cadre de l'obtention de votre carte d'étudiant ; vous avez pris rendez-vous  pour le {{ this.formatDate() }}. Veuillez-vous rendre sur le site de {{ this.getSite() }} pour la prise de photo et l'impression de la dite carte. Nous vous remercions de votre collaboraltion.</h2>
                            </div>

                            <div class="mb-4">
                              <button
                                class="btn" style="background-color:rgba(9, 173, 149, 1); color:white;"
                                type="button"
                                id="button-addon2"
                                @click="dowload_bordereaux"
                              >
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                              </svg>
                              Télécharger le bordereau
                              </button>
                            </div>
                            <!-- end table-responsive -->
                          </div>
                        </div>
                      </div>  
                    </div>
                  </div>
                </div>
              
            </div>
          </div>
        </div>
        
      </div>
      <div class="col-md-12">
        <!-- <Fullcalendar
          :options="calendarOptions"
          :events="events"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
// import Fullcalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridWeek from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from 'moment';
// import  BFormDatepicker  from 'bootstrap-vue';
// import Datepicker from 'vuejs-datepicker';



export default {
  name: "Calendar",
  components:{
    // Fullcalendar
    // BFormDatepicker
    // Datepicker
  },
  data() {
    return {
      calendarOptions: {
        plugins     : [ dayGridPlugin, interactionPlugin, timeGridWeek ],
        weekends: false,
        initialView: 'timeGridWeek',
        events: [],
        the_event: {
          id: '',
          title: '',
          start: '',
          end: '',
        },
        // eventClick: this.showEvent,
      },
      newEvent: {
        annee_academique: "",
        annee_bac: localStorage.getItem('annee_bac'),
        num_table: localStorage.getItem('num_table'),
        site: "",
        start_date: "",
        etudiant: localStorage.getItem('email').slice(-8)
      },
      my_bordereau: {
        matricule: localStorage.getItem('email').slice(-8),
        numTable: null,
        anneeBAC: null,
      },
      minTime: '08:00:00:AM', // Heure minimum autorisée
      maxTime: '05:00:00:PM', // Heure maximum autorisée
      addingMode: true,
      indexToUpdate: "",
      maVariableExterne: null,
      mesInfosEtudiants: {
        numTable: "",
        anneeBAC: "",
        npi: "",
      },
      entiteInfo: null,
      capaciteSite: null,
      site_rdv: null,
      my_date: null,
      annee_academique: null,

      // mesDataEvent: {
      //   annee_academique: "",
      //   entite: "",
      //   annee_bac: "",
      //   num_table: "",
      // },

      maSelctionFiliere: null,
      monBachelier: null,
      mesChoixSite: null,
      monAnneeAcademique: null,
      today: "",
      the_weekend: "",
      url: "scolback.acad.uac.bj"
    };
  },
  mounted() {
    this.getEvents();
    this.today = new Date().toISOString().split("T")[0];
    // if (new Date().getDate() === 6 || new Date().getDate() === 0) this.the_weekend = new Date()

    this.$url.get("calendar")
      .then((resp) => {
        this.maVariableExterne = resp; // Assurez-vous que la structure de resp correspond à la valeur souhaitée
        console.log("Réponse de l'appel AJAX :", this.maVariableExterne);
        // Vous pouvez maintenant utiliser this.maVariableExterne ici ou dans d'autres méthodes du composant.
      })
      .catch((error) => {
        console.error(error);
      });

    this.$url.get("calendar")
      .then((resp) => {
        let mat = localStorage.getItem('email').slice(-8)

          let pp = resp.data

          for (let index = 0; index < pp.length; index++) {
            if (mat == pp[index].etudiant) {
              this.my_date = pp[index].start_date
              break;
            }
          }
          if (this.my_date === null)
            this.my_date = "pas encore definit";
          console.log("la date du rdv est : ", this.my_date);
      })
      .catch((error) => {
        console.error(error);
      });


    this.url1.get('afficher_liste_annee_academique')
      .then(response => {
        // Gérer la réponse réussie ici
        this.monAnneeAcademique = response.data.annees_academiques
        console.log("la liste des années academiques" , this.monAnneeAcademique);

      })
      .catch(error => {
        // Gérer les erreurs ici
        console.error(error);
      });

    this.url1.get('afficher_liste_bachelier')
      .then(response => {
        // Gérer la réponse réussie ici
        this.monBachelier = response.data.bacheliers
        console.log("la liste des bacheliers" , this.monBachelier);

      })
      .catch(error => {
        // Gérer les erreurs ici
        console.error(error);
      });

    this.url1.get('afficher_liste_selection_filiere')
      .then(response => {
        // Gérer la réponse réussie ici
        this.maSelctionFiliere = response.data.selections_filiere
        console.log("la liste des selctions filières" , this.maSelctionFiliere);
      })
      .catch(error => {
        // Gérer les erreurs ici
        console.error(error);
      });

    this.url1.get('afficher_liste_site')
      .then(response => {
        // Gérer la réponse réussie ici
        this.mesChoixSite = response.data.site
        console.log("la liste des sites est: " , this.mesChoixSite);
      })
      .catch(error => {
        // Gérer les erreurs ici
        console.error(error);
      });
  },

  methods: {
    formatDate() {
      return moment(this.appointmentDate).format('DD/MM/YYYY');
    },
    dateDisabled(date) {
      // Disable weekends (Sunday = `0`, Saturday = `6`) and
      // disable days that fall on the 13th of the month
      const weekday = date.getDay()

      return weekday === 0 || weekday === 6
    },
    updateTime() {
      const hours = this.newEvent.start_time.split(':')[0];
      this.newEvent.start_time = `${hours}:00`;
    },

    getAnneeSelection() {
      let annee_aca = this.monAnneeAcademique
      let dateActuelle = new Date()
      var annee = dateActuelle.getFullYear(); // Récupérer l'année (yyyy)
      var mois = (dateActuelle.getMonth() + 1).toString().padStart(2, '0'); // Récupérer le mois (mm) et ajouter un zéro en tête si nécessaire
      var jour = dateActuelle.getDate().toString().padStart(2, '0'); // Récupérer le jour (dd) et ajouter un zéro en tête si nécessaire

      let date_now = annee + '-' + mois + '-' + jour;

      for (let index = 0; index < annee_aca.length; index++) {
        // if (filiere[index].npi == this.mesInfosEtudiants.npi) {
        if ((annee_aca[index].fin >= date_now) && (annee_aca[index].debut <= date_now)) {
            this.annee_academique = annee_aca[index].code
            break
        }
        else this.annee_academique = "aucune annee en cours"
      }
      
      return this.annee_academique;
    },
    getSite() {
      let site = this.mesChoixSite
      let num_table = localStorage.getItem('num_table');
      let filiere = this.maSelctionFiliere
      console.log("le bachelier" , num_table);
      console.log(localStorage);
      // console.log("BAC " , bac);
      // console.log("SITE ", site);
      // console.log("FILLIERE " , filiere);

      // recuperation du nom de l'entite
      for (let index = 0; index < filiere.length; index++) {
        // if (filiere[index].npi == this.mesInfosEtudiants.npi) {
        if (filiere[index].annee_selection == localStorage.getItem('annee_bac')) {
          if (filiere[index].num_table_bac == num_table) {
            this.entiteInfo = filiere[index].specialite.entite_gestionnaire
            // break
          }
          // }
        }
      }
      console.log(this.entiteInfo)

      for (let index = 0; index < site.length; index++) {
        if (site[index].id == this.entiteInfo.id_site) {
            this.site_rdv = site[index].libelle
            break
        }
      }
      console.log("le site pour cette entite est : ", this.site_rdv)

      return this.site_rdv
    },
    getCapaciteSite() {
      let site = this.mesChoixSite
      let filiere = this.maSelctionFiliere
      let num_table = localStorage.getItem('num_table');
      // console.log("le bachelier" , matricule);
      // console.log("BAC " , bac);
      // console.log("SITE ", site);
      // console.log("FILLIERE " , filiere);

      // recuperation du nom de l'entite
      for (let index = 0; index < filiere.length; index++) {
        // if (filiere[index].npi == this.mesInfosEtudiants.npi) {
        if (filiere[index].annee_selection == localStorage.getItem('annee_bac')) {
          if (filiere[index].num_table_bac == num_table) {
            this.entiteInfo = filiere[index].specialite.entite_gestionnaire
            // break
          }
          // }
        }
      }
      // console.log(this.entiteInfo)

      // recuperation du site de l'entite
      for (let index = 0; index < site.length; index++) {
        if (site[index].id == this.entiteInfo.id_site) {
            this.capaciteSite = site[index].capacite
            break
        }
      }
      console.log("le site a pour capacite : ", this.capaciteSite)

      return this.capaciteSite
    },
    checkNumberEvent() {
      let count = 0;
      let pp_resp = this.maVariableExterne.data;
      let mat = localStorage.getItem('email').slice(-8)
      
      // console.log(pp_resp)
      for (let index = 0; index < pp_resp.length; index++) {
        if (mat == pp_resp[index].etudiant) {
          // console.log("puh");
          count++; }
      }
      // console.log(count);
      if (count >= 1) { 
        this.$toast.error(
          "Vous ne pouverz pas prendre plus d'un crenaux !",
          {
          position:
          "top-right",
          duration:
          5000,
        });
        return "error";
      }
      return "OK";
    },

    checkSiteEvent() {
      let count = 0;
      let pp_resp = this.maVariableExterne.data;
      let capacite = this.getCapaciteSite()
      // console.log(capacite)
      
      if (capacite == 0) {
        this.$toast.error(
          "Aucun site n'est disponible pour votre entité ! Veuiller contacter l'administration.",
          {
          position:
          "top-right",
          duration:
          5000,
        });
        return "error";
      }

      // console.log(pp_resp)
      for (let index = 0; index < pp_resp.length; index++) {
        if (this.newEvent.start_date == pp_resp[index].start_date) {
            count++;
        }
      }
      
      console.log(count, " c'est le nombre de count qui est fait");
      if (count >= capacite) {
        this.$toast.error(
          "Le nombre de place disponible pour ce crénaux est atteint. Veuiller prendre un autre crénaux!",
          {
          position:
          "top-right",
          duration:
          5000,
        });
        return "error";
      }
      return "OK";
    },
    week_end() {
      let is_weekend = new Date(this.newEvent.start_date);
      if (is_weekend.getDate() === 6 || is_weekend.getDate() === 0) {
        this.$toast.error(
            "Vous ne devez pas choisir un weekend",
            {
            position:
            "top-right",
            duration:
            500000,
        });
        return "error";
      }
      return "ok";
    },

    isWeek_end(date) {
      if (date.getDate() === 6 || date.getDate() === 0) {
        this.$toast.error(
            "Vous ne devez pas choisir un jour en weekend",
            {
            position:
            "top-right",
            duration:
            500000,
        });
        return false;
      }
      return true;
    },

    addNewEvent() {
        // let date_t = this.newEvent.start_date + 'T' + this.newEvent.start_time
        this.newEvent.site = this.getSite();
      // this.getSite()

      console.log(this.my_date)

      this.newEvent.annee_academique = this.getAnneeSelection()
      console.log(this.newEvent)
      let message = "Rendez-vous pris avec succès. le lieu du rendez vous est " + this.newEvent.site + ".\nPour plus d'information rapprochez vous de la scolarité.";


      let pipo = this.checkNumberEvent()

      if (pipo == "error") {
        this.addingMode = false;
        return ""; }
      

      // la gestion des sites
      let dodo = this.checkSiteEvent()

      if (dodo == "error") return "";
    

      if(this.isWeek_end(this.newEvent.start_date)){
        console.log("le new event final", this.newEvent)
        this.$url
          .post("calendar", {
            ...this.newEvent,
          })
          .then(() => {
            // console.log(data.config)
            // console.log(resp)

            this.getEvents(); // update our list of events
            this.resetForm(); // clear newEvent properties (e.g. title, start_date and start_time)
            // this.send_bordereau()
            this.addingMode = false;
            this.$toast.success(
                message,
                {
                position:
                "top-right",
                duration:
                5000,
            });
            // this.showEvent();
          })
          .catch((err) =>
            console.log("Unable to add new event!", err.response.data)
          );
      }else{
        console.log("Unable to add new event! Day is weekend")
      }
    },
    showEvent(arg) {
      this.addingMode = false;
      const evn = arg.event
      this.indexToUpdate = evn.id;
      const [date, time] = evn.startStr.split('T');
      const dd = time.split('+')
      console.log(evn.title)
      console.log(evn.startStr)
      console.log(this.indexToUpdate)
      this.newEvent = {
        site: "uac",
        start_date: date,
        start_time: dd[0],
        etudiant: localStorage.getItem('email').slice(-8)
      }

      console.log(this.calendarOptions.newEvent)
    },
    updateEvent() {
      this.$url
        .put("calendar/" + this.indexToUpdate, {
          ...this.newEvent,
        })
        .then((resp) => {
          console.log(resp)
          this.resetForm();
          this.getEvents();
          this.addingMode = !this.addingMode;
        })
        .catch((err) =>
          console.log("Unable to update event!", err.response.data)
        );
    },
    deleteEvent() {
      this.$url
        .delete("calendar/" + this.indexToUpdate)
        .then((resp) => {
          console.log(resp);
          this.resetForm();
          this.getEvents();
          this.addingMode = !this.addingMode;
          this.$toast.error(
            "Votre rendez vous à été bien supprimer",
            {
            position:
            "top-right",
            duration:
            5000,
        });
        })
        .catch((err) =>
          console.log("Unable to delete event!", err.response.data)
        );
    },
    cancelEvent() {
      this.resetForm();
      this.getEvents();
      this.addingMode = !this.addingMode;
    },
    getEvents() {
      this.$url
        .get("calendar")
        .then((resp) => {
          
          let mat = localStorage.getItem('email').slice(-8)

          let pp = resp.data

          for (let index = 0; index < pp.length; index++) {
            if (mat == pp[index].etudiant) {
              let date_t = pp[index].start_date + 'T' + pp[index].start_time
              this.calendarOptions.the_event.id = pp[index].id
              this.calendarOptions.the_event.title = pp[index].etudiant
              this.calendarOptions.the_event.start = date_t
              this.calendarOptions.the_event.end = date_t
              this.calendarOptions.events.push(this.calendarOptions.the_event)
              console.log(this.calendarOptions.the_event)
            }
            this.calendarOptions.the_event = {title: '', start:'', end:''}
            
          }
          // console.log(this.calendarOptions.events)
        })
        .catch((err) => console.log(err.response.data));
    },
    resetForm() {
      Object.keys(this.newEvent).forEach((key) => {
        return (this.newEvent[key] = "");
      });
    },
    send_bordereau() {
      // let matricule = localStorage.getItem('email').slice(-8)
      // let bac = this.monBachelier
      // console.log("le bachelier" , matricule);
      
      // console.log("FILLIERE " , filiere);

      // recuperation de l'etudiant
      this.my_bordereau.anneeBAC = localStorage.getItem('annee_bac')
      this.my_bordereau.numTable = localStorage.getItem('num_table')
        
      console.log(this.my_bordereau)

      this.$url
        .post("get_bordereau",this.my_bordereau)
          
        .then(() => {
          this.$toast.success(
            "voici le bordereau",
            {
            position:
            "top-right",
            duration:
            5000,
        });
        })
        .catch((err) =>
          console.log("Error of type post on the 'get_bordereau'", err.response.data)
        );
    },
    dowload_bordereaux() {

      this.my_bordereau.anneeBAC = localStorage.getItem('annee_bac')
      this.my_bordereau.numTable = localStorage.getItem('num_table')

      console.log(this.my_bordereau)
      this.$url
        .post("get_bordereau",this.my_bordereau, {
        responseType: 'blob', // Indiquez que la réponse est un fichier binaire (le PDF)
      })
      .then((response) => {
        // Créez un lien pour le téléchargement du fichier PDF
        const url = window.URL.createObjectURL(new Blob([response.data]));

        // Créez un élément d'ancrage pour déclencher le téléchargement
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'bordereau.pdf'); // Nom du fichier PDF téléchargé
        document.body.appendChild(link);
        link.click();

        // Libérez la mémoire du lien
        window.URL.revokeObjectURL(url);


        // const blob = new Blob([response.data], { type: 'application/pdf' })
        // const link = document.createElement('a')
        // link.href = URL.createObjectURL(blob)
        // link.download = fiche.name
        // link.click()
        // URL.revokeObjectURL(link.href)

        // Affichez une notification de succès (facultatif)
        this.$toast.success("Voici le bordereau", {
          position: "top-right",
          duration: 5000,
        });
      })
      .catch((error) => {
      console.error("Erreur lors du téléchargement du fichier PDF :", error);
      // Affichez une notification d'erreur (facultatif)
      this.$toast.error("Erreur lors du téléchargement du bordereau");
    });
    },
  },

  watch: {
    indexToUpdate() {
      return this.indexToUpdate;
    }
  }

};
</script>

<style lang="css" scoped>
    .fc-title {
        color: #fff;
    }
    .fc-title:hover {
        cursor: pointer;
    }
</style>
