<template>
    <footer class="footer">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-6">2023 © Université d'Abomey-Calavi.</div>
            <div class="col-sm-6">
              <div class="text-sm-end d-none d-sm-block">
                Design & Develop by SPTIC
              </div>
            </div>
          </div>
        </div>
    </footer>
</template>

<script>
export default {
   name: 'Footer'
}
</script>

<style lang="scss" scoped>

</style>