<template>
    <p class="text-danger">
        
    </p>
</template>

<script>
export default {
    name: 'AddUser'
}
</script>

<style lang="scss" scoped>

</style>