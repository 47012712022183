import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {axiosInstance,axiosInstance1,axiosInstance2} from '@/axios'
import Toaster from '@meforma/vue-toaster';


const app = createApp(App);
app.config.globalProperties.$url= axiosInstance;
app.config.globalProperties.$url1= axiosInstance1;
app.config.globalProperties.$url2= axiosInstance2;


app.use(store).use(Toaster).use(router);
app.mount('#app')
