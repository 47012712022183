import axios from 'axios';
import store from '@/store';

const createAxiosInstance = (baseURL) => {
  const instance = axios.create({
    baseURL,
  });

  instance.interceptors.request.use(
    function(config) {
      const token = store.getters.user ? store.getters.user : null;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    function(error) {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    function(response) {
      return response;
    },
    function(error) {
      if (error.response.status == 401) {
        localStorage.removeItem('user_id');
        localStorage.removeItem('email');
        localStorage.removeItem('user_name');
        localStorage.removeItem('token');
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

export const axiosInstance = createAxiosInstance('https://etudiantback.uac.bj/api/');
export const axiosInstance1 = createAxiosInstance('https://scolback.uac.bj/api/'); // Remplacez par votre deuxième URL
export const axiosInstance2 = createAxiosInstance('https://inscriptionback.uac.bj/api/'); // Remplacez par votre deuxième URL
