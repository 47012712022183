<template>
  <div class="row">
    <div class="col-xl-12"  style="height:750px;">
      <div class="card h-100">
        <div class="card-body d-flex justify-content-center align-items-center">
            
            <div class="card container py-5 shadow-lg">
                <div class="">
                    <p class="text-center text-choix-email">
                    <span v-if="this.email.length==1" class="fs-5">
                        Nous vous avons généré ci-dessous une adresse mail institutionnelle. Veillez la garder pour les prochains services
                    </span> 
                    </p>
                    <div class="d-flex justify-content-center">
                        <img src="../../../public/assets/images/e-mail.png" alt="">
                    </div>
                    <form @submit.prevent="saveEmail">
                        <div class="d-flex  justify-content-center flex-column align-items-center">
                            <input type="text" class="w-75 mt-3 search-email-input border-0 text-center fs-5" readonly v-model="this.email[0]" placeholder="Ex: nom.prenom@students.uac.bj">
                            <button class="btn-submit mt-3" type="submit">
                                Continuer
                            </button>
                        </div>
                    </form>
                </div>                
            </div>
            
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "AskEmail",
  data(){
    return {
        email : ""
    }
  },
 mounted(){
    this.$url.post('get_etudiant_email', {
        num_table:localStorage.getItem('num_table'),
        annee_bac:localStorage.getItem('annee_bac'),
    })
    .then(response => {
        this.email = response.data.email
        console.log(response.data);
       
    })
},
methods:{
    saveEmail(){
        window.location.href='/planning'
    }
}
};
</script>

<style lang="css" scoped>
.text-choix-email {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color:#000;
}
.search-email-input{
    height: 47px;
    background: #F3F3F9;
    border-radius: 5px;
}
.btn-submit{
    background: #09AD95;
    font-size: 16px;
    line-height: 20px;
    border-radius: 5px;
    width: 107px;
    height: 35px;
    color:white;
    border:none;
}
</style>
