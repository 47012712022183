import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    ficheBacheliers : "",
    firstLogin: true,
    site_id: null,
    is_submited:false,
    apointement_date: null,
    can_take_apointment: false,
    currrentRoute: null,
    user: null
  },
  getters: {
    currrentRoute(state) {
      return state.currrentRoute
    },
    user(state){
      return state.user;
  }
  },

  mutations: {
    getFicheBachelier(state, studentFiches) {
      state.ficheBacheliers = studentFiches;
    },
    setSiteId(state, value) {
      state.site_id = value;
    },
    setIsSubmited(state, value) {
      state.is_submited = value;
    },
    setDate(state, value){
      state.apointement_date = value;
    },
    can_take_apointment(state, value){
      state.can_take_apointment = value
    },
    setCurrentRoute (state,activeRoute ){
      state.currrentRoute = activeRoute
    },
    user: (state, user) => {
      state.user = user;
  },
  },
  actions: {
    saveStudentFiche(context, studentFiches){
      context.commit('getFicheBachelier', studentFiches)
    },
    site_id(context, site_id){
      context.commit('setSiteId', site_id)
    },
    is_submited(context, is_submited){
      context.commit('setIsSubmited', is_submited)
    },
    apointement_date(context, apointement_date){
      context.commit('setDate', apointement_date)
    },
    can_take_apointment(context, can_take_apointment){
      context.commit('can_take_apointment', can_take_apointment)
    },
    activeRoute(context, activeRoute){
      context.commit('setCurrentRoute', activeRoute)
    },
    user(context, user){
      context.commit('user', user);
  },
    


  },
  plugins: [createPersistedState()],

  modules: {
    
  }
})
