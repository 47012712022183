import { createRouter, createWebHistory } from 'vue-router'
//import HomeView from '../views/HomeView.vue'
import Template from '@/views/Pages/Template.vue'
import Dashboard from '@/views/Pages/Dashboard.vue'
import Calendar from '@/views/Pages/Calendar.vue'
import AddUser from '@/views/Pages/AddUser.vue'
import AskEmail from '@/views/Pages/AskEmail.vue'
import Login from '@/views/Auth/Login.vue'
import {axiosInstance} from '@/axios'

const routes = [
  {
    path: '/admin',
    name: 'home',
    component: Template,
    
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
          requiresAuth: true 
        },
      },
      {
        path: '/addUser',
        name: 'addUser',
        component: AddUser,
        meta: {
          requiresAuth: true 
        },
      },
      {
        path: '/askEmail',
        name: 'askEmail',
        component: AskEmail,
        meta: {
          requiresAuth: true 
        },
      },
      {
        path: '/planning',
        name: 'planning',
        component: Calendar,
        meta: {
          requiresAuth: true 
        },
      },
      {
        path: '/calendar',
        name: 'calendar',
        component: Calendar,
        meta: {
          requiresAuth: true 
        },
      },
      {
        path: '/about',
        name: 'about',
        meta: {
          requiresAuth: true,
          layout: Template
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
        children: [
          {
            path: '/fiche_inscription',
            name: "fiche_inscription",
            meta: {
              requiresAuth: true,
            },
            component: ()=> import('../views/Pages/Step1.vue')
          },
          {
            path: '/fiche_valide',
            name: "fiche_valide",
            meta: {
              requiresAuth: true,
            },
            beforeEnter: (to, from, next) => {
              axiosInstance.post('get_stockage',{
                matricule:  localStorage.getItem('email').slice(-8)
              })
              .then(response => {
                if(response.data.data.is_printed_cart){
                  next();
                }else{
                  alert("Vous n'êtes pas autoriser à aller l'étape suivante. Veuillez d'abord honorer le rendez-vous pour impression de votre carte.")
                  next('/fiche_inscription')
                }
              
              })
              .catch(error => {
                console.log(error)
                alert("Une erreur s'est produite lors du chargement de la page suivante.")
                next('/fiche_inscription');
              });
            },
            component: ()=> import('../views/Pages/Step3.vue')
          },
          {
            path: '/rendez_vous',
            name: "rendez_vous",
            meta: {
              requiresAuth: true,
            },
            beforeEnter: (to, from, next) => {
              axiosInstance.post('get_stockage',{
                matricule:  localStorage.getItem('email').slice(-8)
              })
              .then(response => {
               
                if(response.data.data.validite){
                  next();
                }else{
                  alert("Vous n'êtes pas autorisé à prendre rendez-vous. Vérifier si des frais sont liés à votre fiche. Si oui, veuillez d'abord les payer puis revenez pour continuez le processus. Merci pour votre collaboration.")
                  next('/fiche_inscription')
                }
              
              })
              .catch(error => {
                console.log(error)
                alert("Une erreur s'est produite lors du chargement de la page suivante.")
                next('/fiche_inscription');
              });
            },
            component: ()=> import('../views/Pages/Step2.vue')
          },
          {
            path: '/bordereau',
            name: "bordereau",
            meta: {
              requiresAuth: true,
            },
            component: ()=> import('../views/Pages/Step3.vue')
          }

        ]
      }
    ],
    meta: {
      requiresAuth: false 
    },
  },
  {
    path: '/',
    name: 'login',
    component: Login,
    meta: {
      requiresAuth: false // Propriété indiquant que l'accès à la page de connexion ne nécessite pas d'authentification
    }
  },
  {
    path: '/forgot_password',
    name: 'forgot_password',
    component: () => import("../views/Auth/ForgotPassword.vue"),
    meta: {
      requiresAuth: false // Propriété indiquant que l'accès à la page de connexion ne nécessite pas d'authentification
    }
  },
  {
    path: '/reset-password/:id',
    name: 'reset-password',
    component: () => import("../views/Auth/ResetPassword.vue"),
    meta: {
      requiresAuth: false // Propriété indiquant que l'accès à la page de connexion ne nécessite pas d'authentification
    }
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to, from, next) => {
  
  const isAuthenticated = localStorage.getItem('token'); // Vérifiez si l'utilisateur est déjà authentifié
  
  if (to.meta.requiresAuth) {
    
    if (isAuthenticated) {
      try {
        await axiosInstance.get('user')
        next(); 
        
      } catch (error) {
        // L'utilisateur est déjà authentifié, mais le token est invalide
        localStorage.removeItem('user_id')
        localStorage.removeItem('email')
        localStorage.removeItem('user_name')
        localStorage.removeItem('token')
        localStorage.removeItem('npi')

        next('/'); // Redirigez vers la page de connexion
      }
    } else {
      next('/'); // Redirigez vers la page de connexion si l'utilisateur n'est pas authentifié
    }
  } else {
    if (isAuthenticated) {
      next('/dashboard'); // Redirigez vers une page différente, par exemple, le tableau de bord, si l'utilisateur est déjà authentifié
    } else {
      next(); 
    }
  }
});

export default router
